.card-placeholder {
  min-height: 11.5rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

@media only screen and (max-width: 700px) {
  .card-placeholder {
    display: none;
  }
}
