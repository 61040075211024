@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
:root {
  font-family: Roboto, Inter, Avenir, Helvetica, Arial, sans-serif;
  // font-size: 16px;
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #181922;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}

h4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

h5 {
  font-size: 0.72rem;
  font-weight: 700;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #181922;
}
.ant-app {
  width: 100%;
}

input {
  color: black;
  background-color: #ffffff;
}
label {
  color: #ffffff !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 0.4375rem;
  height: 0.4375rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 0.625rem;
  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.625rem;
  border-radius: 0.625rem;
  background: rgba(240, 240, 240, 0.6);
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(240, 240, 240, 0.6);
}

.link {
  color: #009dff;
  text-decoration: underline;
  cursor: pointer;
}

.main-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.content-wrapper {
  height: 100%;
  .main-content {
    padding: 0.25rem 2.25rem 0;
    // margin-bottom: 8.25rem;
    height: 100%;
    padding-top: 1.25rem;
  }
}

.table-wrapper {
  // overflow: auto;
  // max-height: 37.5rem;

  table {
    border-collapse: collapse;
    width: 100%;
    .sort-button {
      padding-inline-start: 0.4375rem;
    }
    tr {
      height: 3.75rem;
      td {
        padding-inline: 0.4375rem;
        min-width: 4.6875rem;
      }
    }

    .tr:nth-child(even) {
      background-color: #f2f2f215;
    }
  }
}

.table-row-selected {
  background-color: rgba(255, 255, 255, 0.04);
}

.loader {
  margin: 1.25rem 0;
  margin-bottom: 1.25rem;
  padding: 1.25rem 3.125rem;
  text-align: center;
  border-radius: 0.25rem;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.25rem;
  > div {
    flex-grow: 1;
    flex-basis: 16%;
    margin-bottom: 1.5625rem;
  }
  .table-card {
    flex-basis: 100% !important;
  }
}

.ant-table-tbody-virtual-holder .ant-table-cell {
  display: flex;
  align-items: center;
}

.green {
  color: #00c900;
  white-space: nowrap;
}
.red {
  color: #ff0000;
  white-space: nowrap;
}
.yellow {
  color: #dfe300;
}

h2,
h3,
h4,
h5 {
  padding-block: 0.125rem;
}

.splash-screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5625rem;
}

.ant-form-item {
  margin-bottom: 0.625rem;
}

.ant-form-item-label {
  padding-bottom: 0.1875rem !important;
}

@media only screen and (max-width: 700px) {
  .content-wrapper {
    .main-content {
      padding: 1rem 0.5rem 0.7rem;
    }
  }

  .cards-wrapper {
    > div {
      flex-basis: 33% !important;
    }
  }
  .ant-picker-panels {
    flex-direction: column;
  }
}
