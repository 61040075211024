.ai-magician-wrapper {
  .top {
    display: flex;
    > div {
      flex-grow: 1;
    }
    > :nth-child(1) {
      flex-basis: 40%;
      max-width: 42%;
    }
    > :nth-child(2) {
      flex-basis: 55%;
    }
  }
  .results-table-buttons {
    display: flex;
    align-items: center;
    padding-block-end: 0.625rem;
    button {
      margin-inline-end: 0.625rem;
    }
  }
  .total-profit-wrapper {
    display: flex;
    align-items: baseline;
  }
}

@media only screen and (max-width: 700px) {
  .ai-magician-wrapper {
    .top {
      > :nth-child(1) {
        flex-basis: 100%;
        max-width: 100%;
      }
      > :nth-child(2) {
        flex-basis: 100%;
      }
    }
    .cards-wrapper {
      flex-wrap: wrap;
      > div {
        flex-basis: 100% !important;
      }
    }
  }
}
