.generic-card-wrapper {
  background-color: #21222d;
  box-shadow: 0.1875rem 0.375rem 0.875rem 0 rgb(0 0 0 / 10%);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: auto;
  &.card-closed .card-header {
    &:hover {
      background-color: #292b3a;
    }
  }
  .card-header {
    padding-inline: 1rem;
    padding-block-start: 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    transition: 0.15s background-color ease-in-out;
    cursor: pointer;
    .card-title {
      display: flex;
    }
  }

  .content {
    padding-inline: 1rem;
    padding-block: 1rem;
    overflow: auto;
  }
}

.card-closed {
  min-height: 0.3125rem !important;
  height: 100%;
  .card-header {
    padding-block-end: 1rem;
  }
}
