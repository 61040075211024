@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");
.drawer {
  background-color: #21222d;
  width: 14.375rem;
  min-width: 14.375rem;
  position: relative;

  .close-button {
    position: absolute;
    right: 0.4375rem;
    top: 0.4375rem;
    padding: 0.3125rem;
    svg {
      width: 1.25rem;
      height: 100%;
    }
  }

  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    .logo-wrapper {
      display: flex;
      align-items: center;
      border-radius: 0.625rem;
      user-select: none;
      cursor: pointer;
      .logo-icon {
        width: 2rem;
      }
    }
    h1 {
      color: #ffffff !important;
      padding-top: 0.6875rem;
      padding-inline-start: 0.25rem;
      font-size: 2rem;
      font-family: "Kalam", cursive;
    }
  }
  .buttons-wrapper {
    padding-top: 0.625rem;
    margin: 0.625rem 0.9375rem;
    .drawer-button {
      display: flex;
      align-items: center;
      font-size: 1rem;
      height: 3.125rem;
      padding-inline-start: 0.9375rem;
      border-radius: 0.9375rem;
      margin-block-end: 0.625rem;
      color: #b5b4b9;
      transition: all 0.2s;
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        background-color: #181922;
      }
    }
    .drawer-button-active {
      background-color: #181922;
    }
  }
}

.drawer-closed {
  display: none;
  width: 0;
  min-width: 0;
  opacity: 0;
}

@media only screen and (max-width: 43.75rem) {
  .drawer {
    width: 100%;
    min-width: 100%;
  }
}
