.app-header {
  .header {
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.0625rem solid #8d8d8d40;

    .header-left {
      display: flex;
      align-items: center;
      .real-demo-switch {
        padding-inline-start: 2rem;
      }
    }

    .menu-button {
      margin-inline-start: 2.1875rem;
      padding: 0.3125rem;
      cursor: pointer;
      svg {
        width: 1.5625rem;
        height: 100%;
      }
    }
    .user-profile-name {
      background-color: #21222d;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 1.25rem;
      padding-inline: 0.5rem 0.9375rem;
      height: 2.8125rem;
      border-radius: 3.125rem;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      &:hover {
        background-color: #ffffff10;
        cursor: pointer;
      }
      .user-fullname {
        font-weight: 500;
        padding-inline-start: 0.625rem;
        // font-size: 1.1rem;
      }
      .down-arrow {
        svg {
          // color: #666c75;
          padding-top: 0.125rem;
          margin-inline-start: 0.3125rem;
          height: 0.875rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .menu-button {
    margin-inline-start: 1rem !important;
  }
  .user-profile-name {
    margin-inline-end: 1rem !important;
  }

  .app-header {
    .header {
      .header-left {
        .real-demo-switch {
          padding-inline-start: 1rem;
        }
      }
    }
  }
}
