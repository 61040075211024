.blob {
  background: rgb(0, 201, 0);
  border-radius: 50%;
  margin: 0.625rem 0.8125rem 0.625rem 0.8125rem;
  height: 1.25rem;
  width: 1.25rem;

  box-shadow: 0 0 0 0 rgba(rgb(0, 201, 0), 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(rgb(0, 201, 0), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.625rem rgba(rgb(0, 201, 0), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(rgb(0, 201, 0), 0);
  }
}
